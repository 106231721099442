import ApiService from './ApiService'

class NotificationService extends ApiService {
  listData = async (query = null) => {
    return await this.axiosIns.get(`notification`, {
      params: query
    })
  }

  clearAll = async () => {
    return await this.axiosIns.post(`notification/clear`)
  }

  clearOne = async (id) => {
    return await this.axiosIns.post(`notification/${id}/clear`)
  }
}

export default NotificationService