import ApiService from './ApiService'

class ProfileService extends ApiService {
  changeProfile = async (body) => {
    return await this.axiosIns.post(`profile/edit`, body)
  }

  changePassword = async (body) => {
    return await this.axiosIns.post(`profile/change-password`, body)
  }
}

export default ProfileService