import ApiService from './ApiService'

class FileStorageService extends ApiService {
  upload = async (fileObject) => {
    if(Object.entries(fileObject).length > 0) {
      let formData = new FormData()
      formData.append('fileUpload', fileObject)
      let uploadResult = await this.axiosIns.post(`file-storage`, formData)
      if(uploadResult.isSuccess) {
        return {isSuccess: true, message: uploadResult.data.message, fileId: uploadResult.data.result.fileId}
      } else {
        return {isSuccess: false, message: uploadResult.data.message}
      }
    }
  }
}

export default FileStorageService