import ApiService from './ApiService'

class CompanyService extends ApiService {
  listData = async (query = null) => {
    return await this.axiosIns.get(`master/company`, {
      params: query
    })
  }

  getData = async (id) => {
    return await this.axiosIns.get(`master/company/${id}`)
  }

  createData = async (body) => {
    return await this.axiosIns.post(`master/company`, body)
  }

  updateData = async (id, body) => {
    return await this.axiosIns.put(`master/company/${id}`, body)
  }

  deleteData = async (id) => {
    return await this.axiosIns.delete(`master/company/${id}`)
  }
}

export default CompanyService