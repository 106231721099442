import ApiService from './ApiService'

class UserService extends ApiService {
  listData = async (query = null) => {
    return await this.axiosIns.get(`user`, {
      params: query
    })
  }

  listWorkOwnerData = async () => {
    return await this.axiosIns.get(`user/work-owner`)
  }

  lisAreaOwnerData = async () => {
    return await this.axiosIns.get(`user/area-owner`)
  }

  listSectionManagerData = async () => {
    return await this.axiosIns.get(`user/section-manager`)
  }

  listContractorAdminData = async (companyId) => {
    return await this.axiosIns.get(`user/contractor-admin?companyId=${companyId}`)
  }

  listContractorData = async (companyId) => {
    return await this.axiosIns.get(`user/contractor?companyId=${companyId}`)
  }

  getData = async (id) => {
    return await this.axiosIns.get(`user/${id}`)
  }

  createData = async (body) => {
    return await this.axiosIns.post(`user`, body)
  }

  updateData = async (id, body) => {
    return await this.axiosIns.put(`user/${id}`, body)
  }

  approvalData = async (id, body) => {
    return await this.axiosIns.post(`user/${id}/approval`, body)
  }

  deleteData = async (id) => {
    return await this.axiosIns.delete(`user/${id}`)
  }

  setBlacklist = async (userId, blacklistValue, blacklistDescription, workerId) => {
    let blackList = blacklistValue ? blacklistValue : 'N'
    return await this.axiosIns.post(`user/${userId}/blacklist`, {userBlacklist: blackList, userBlacklistDesc: blacklistDescription, workerId: workerId})
  }

  scoreRecord = async (userId, body) => {
    return await this.axiosIns.post(`user/${userId}/score-record`, body)
  }
}

export default UserService