import ApiService from './ApiService'

class UserAttachmentService extends ApiService {
  listData = async (query = null) => {
    return await this.axiosIns.get(`user-attachment`, {
      params: query
    })
  }

  saveData = async (body) => {
    return await this.axiosIns.post(`user-attachment`, body)
  }

  updateData = async (id, body) => {
    return await this.axiosIns.put(`user-attachment/${id}`, body)
  }

  deleteData = async (id) => {
    return await this.axiosIns.delete(`user-attachment/${id}`)
  }
}

export default UserAttachmentService