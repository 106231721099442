import jwtService from '../@core/auth/jwt/jwtService'

class ApiService extends jwtService {
  constructor() {
    super()

  }
}

export default ApiService
