import ApiService from './ApiService'

class ReportService extends ApiService {
  listWorkPermit = async (query = null) => {
    return await this.axiosIns.get(`report/work-permit`, {
      params: query
    })
  }

}

export default ReportService