import ApiService from './ApiService'

class OfflineTrainingService extends ApiService {
  listData = async (query = null) => {
    return await this.axiosIns.get(`offline-training`, {
      params: query
    })
  }

  createData = async (body) => {
    return await this.axiosIns.post(`offline-training`, body)
  }

  approveData = async (id, body) => {
    return await this.axiosIns.post(`offline-training/${id}`, body)
  }

  deleteData = async (id) => {
    return await this.axiosIns.delete(`offline-training/${id}`)
  }
}

export default OfflineTrainingService