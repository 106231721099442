import ApiService from './ApiService'

class TrainingService extends ApiService {
  listData = async (query = null) => {
    return await this.axiosIns.get(`training/history`, {
      params: query
    })
  }

  createData = async (body) => {
    return await this.axiosIns.post(`training/exam`, body)
  }

  getExam = async () => {
    return await this.axiosIns.get(`training/exam`)
  }
}

export default TrainingService