import ApiService from './ApiService'

class WorkService extends ApiService {
  listData = async (query = null) => {
    return await this.axiosIns.get(`work-permit`, {
      params: query
    })
  }

  listJsaData = async (id, query = null) => {
    return await this.axiosIns.get(`work-permit/${id}/jsa`, {
      params: query
    })
  }

  listActivityData = async (id, query = null) => {
    return await this.axiosIns.get(`work-permit/${id}/activity`, {
      params: query
    })
  }

  getData = async (id) => {
    return await this.axiosIns.get(`work-permit/${id}`)
  }

  createData = async (body) => {
    return await this.axiosIns.post(`work-permit`, body)
  }

  updateData = async (id, body) => {
    return await this.axiosIns.put(`work-permit/${id}`, body)
  }

  approval = async (id, body) => {
    return new Promise(resolve => {
      this.axiosIns.post(`work-permit/${id}/approval`, body)
        .then(result => {
          resolve(result)
        })
    })
  }

  checkIn = async (id, body) => {
    return new Promise(resolve => {
      this.axiosIns.post(`work-permit/${id}/check-in`, body)
        .then(result => {
          resolve(result)
        })
    })
  }

  checkOut = async (id) => {
    return new Promise(resolve => {
      this.axiosIns.post(`work-permit/${id}/check-out`)
        .then(result => {
          resolve(result)
        })
    })
  }


  checkInHistory = async (id) => {
    return new Promise(resolve => {
      this.axiosIns.get(`work-permit/${id}/check-in-history`)
        .then(result => {
          resolve(result)
        })
    })
  }

  getSafetyCheck = async (id) => {
    return new Promise(resolve => {
      this.axiosIns.get(`work-permit/${id}/safety-check`)
        .then(result => {
          resolve(result)
        })
    })
  }

  safetyCheck = async (id, body) => {
    return new Promise(resolve => {
      this.axiosIns.post(`work-permit/${id}/safety-check`, body)
        .then(result => {
          resolve(result)
        })
    })
  }

  extendRequest = async (id, body) => {
    return new Promise(resolve => {
      this.axiosIns.post(`work-permit/${id}/extend`, body)
        .then(result => {
          resolve(result)
        })
    })
  }

  closeJob = async (id) => {
    return new Promise(resolve => {
      this.axiosIns.post(`work-permit/${id}/close`)
        .then(result => {
          resolve(result)
        })
    })
  }

  safetyCloseJob = async (id) => {
    return new Promise(resolve => {
      this.axiosIns.post(`work-permit/${id}/safety-close`)
        .then(result => {
          resolve(result)
        })
    })
  }

  deleteData = async (id) => {
    return await this.axiosIns.delete(`work-permit/${id}`)
  }
}

export default WorkService