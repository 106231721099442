import ApiService from './ApiService'

class ComplianceService extends ApiService {
  listData = async (query = null) => {
    return await this.axiosIns.get(`master/compliance`, {
      params: query
    })
  }

  getData = async (id) => {
    return await this.axiosIns.get(`master/compliance/${id}`)
  }

  createData = async (body) => {
    return await this.axiosIns.post(`master/compliance`, body)
  }

  updateData = async (id, body) => {
    return await this.axiosIns.put(`master/compliance/${id}`, body)
  }

  deleteData = async (id) => {
    return await this.axiosIns.delete(`master/compliance/${id}`)
  }
}

export default ComplianceService