import ApiService from './ApiService'

class WorkTypeService extends ApiService {
  listData = async (query = null) => {
    return await this.axiosIns.get(`master/work-type`, {
      params: query
    })
  }

  getData = async (id) => {
    return await this.axiosIns.get(`master/work-type/${id}`)
  }

  createData = async (body) => {
    return await this.axiosIns.post(`master/work-type`, body)
  }

  updateData = async (id, body) => {
    return await this.axiosIns.put(`master/work-type/${id}`, body)
  }

  deleteData = async (id) => {
    return await this.axiosIns.delete(`master/work-type/${id}`)
  }
}

export default WorkTypeService